import * as React from "react";
//import createSvgIcon from "@material-ui/icons/utils/createSvgIcon";

export default ({ size, style }) => {
  const color = (style ? style.color : null) || "rgba(0, 0, 0, 0.87)";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size || 32}
      height={size || 32}
      viewBox="0 0 200 200"
      style={style}
    >
      <path
        fill={color}
        stroke={color}
        //stroke-width="1"
        d="M 94.00,10.47
             C 103.40,9.16 111.48,11.31 117.61,19.01
               126.65,30.38 122.13,49.18 109.00,55.10
               104.83,56.98 101.48,57.09 97.00,56.99
               79.74,56.57 70.18,37.99 77.40,23.00
               80.92,15.70 86.35,12.29 94.00,10.47 Z
             M 96.00,22.40
             C 82.33,26.88 85.86,43.65 97.00,45.14
               109.67,46.84 115.79,29.24 103.98,23.31
               101.20,21.92 98.96,22.06 96.00,22.40 Z
             M 150.00,43.53
             C 160.51,41.80 169.67,43.14 176.61,52.00
               186.47,64.60 179.46,84.92 164.00,88.76
               154.16,91.20 143.91,88.51 137.80,79.99
               134.66,75.60 134.06,72.23 134.00,67.00
               133.88,55.34 138.38,47.15 150.00,43.53 Z
             M 37.00,45.47
             C 44.91,44.37 53.13,46.07 58.79,52.10
               69.35,63.34 66.13,83.40 52.00,90.08
               47.98,91.98 44.34,92.09 40.00,91.99
               25.57,91.64 17.68,80.70 18.01,67.00
               18.09,63.97 18.31,61.83 19.53,59.00
               22.94,51.14 28.98,47.37 37.00,45.47 Z
             M 156.00,54.38
             C 151.02,55.64 147.65,57.76 146.20,63.00
               144.02,70.85 150.78,78.90 159.00,77.62
               172.78,75.47 172.42,54.06 156.00,54.38 Z
             M 39.00,57.40
             C 24.70,62.00 28.89,78.66 40.00,80.14
               52.97,81.88 57.82,64.21 46.96,58.45
               44.10,56.94 42.06,57.05 39.00,57.40 Z
             M 107.04,69.74
             C 110.48,68.90 113.83,68.48 116.57,71.31
               120.66,75.53 119.13,83.87 112.00,84.85
               102.38,86.18 98.12,74.94 107.04,69.74 Z
             M 83.11,71.02
             C 86.28,69.91 89.86,69.35 92.77,71.60
               97.75,75.44 96.64,84.79 89.00,85.85
               79.74,87.13 75.55,77.14 83.11,71.02 Z
             M 120.02,88.75
             C 132.52,86.36 133.82,101.36 125.96,104.40
               116.68,107.99 108.26,95.15 120.02,88.75 Z
             M 75.02,89.61
             C 87.68,88.53 87.49,103.68 79.00,105.68
               69.17,108.00 64.31,93.84 75.02,89.61 Z
             M 109.04,108.74
             C 121.35,106.03 124.70,120.57 114.98,124.26
               105.73,127.77 98.97,114.59 109.04,108.74 Z
             M 152.00,108.53
             C 160.76,107.10 169.15,107.56 175.67,114.30
               188.41,127.45 181.94,150.19 164.00,154.32
               139.75,159.91 124.52,129.30 143.02,113.21
               146.11,110.52 148.17,109.67 152.00,108.53 Z
             M 37.00,109.52
             C 42.65,108.62 47.67,108.79 53.00,111.11
               65.81,116.68 70.25,134.75 62.35,146.00
               57.06,153.54 49.98,156.35 41.00,155.96
               15.43,154.84 9.74,117.03 37.00,109.52 Z
             M 86.02,109.74
             C 98.54,107.01 101.14,121.75 91.98,125.26
               82.63,128.83 75.78,115.51 86.02,109.74 Z
             M 158.00,119.42
             C 153.44,120.39 149.53,122.36 147.74,127.00
               144.91,134.35 151.08,143.34 159.00,142.77
               174.10,141.69 173.50,119.81 158.00,119.42 Z
             M 39.00,121.51
             C 24.17,127.92 32.27,147.27 46.00,143.57
               53.46,141.56 57.83,131.18 50.78,124.23
               47.25,120.74 43.57,120.71 39.00,121.51 Z
             M 95.00,143.53
             C 100.89,142.57 107.64,142.62 113.00,145.56
               133.15,156.60 127.40,191.07 100.00,189.96
               73.14,188.88 69.63,151.11 95.00,143.53 Z
             M 98.00,155.43
             C 82.67,161.52 90.81,181.83 105.00,177.35
               113.68,174.61 115.54,163.25 108.79,157.43
               105.56,154.64 101.93,154.84 98.00,155.43 Z"
      />
    </svg>
  );
};

// export default createSvgIcon(
//   <React.Fragment>
//     <path fill="none" d="M0 0h24v24H0z" />
//     <path
//       id="Selection"
//       fill="white"
//       stroke="white"
//       stroke-width="1"
//       d="M 94.00,10.47
//            C 103.40,9.16 111.48,11.31 117.61,19.01
//              126.65,30.38 122.13,49.18 109.00,55.10
//              104.83,56.98 101.48,57.09 97.00,56.99
//              79.74,56.57 70.18,37.99 77.40,23.00
//              80.92,15.70 86.35,12.29 94.00,10.47 Z
//            M 96.00,22.40
//            C 82.33,26.88 85.86,43.65 97.00,45.14
//              109.67,46.84 115.79,29.24 103.98,23.31
//              101.20,21.92 98.96,22.06 96.00,22.40 Z
//            M 150.00,43.53
//            C 160.51,41.80 169.67,43.14 176.61,52.00
//              186.47,64.60 179.46,84.92 164.00,88.76
//              154.16,91.20 143.91,88.51 137.80,79.99
//              134.66,75.60 134.06,72.23 134.00,67.00
//              133.88,55.34 138.38,47.15 150.00,43.53 Z
//            M 37.00,45.47
//            C 44.91,44.37 53.13,46.07 58.79,52.10
//              69.35,63.34 66.13,83.40 52.00,90.08
//              47.98,91.98 44.34,92.09 40.00,91.99
//              25.57,91.64 17.68,80.70 18.01,67.00
//              18.09,63.97 18.31,61.83 19.53,59.00
//              22.94,51.14 28.98,47.37 37.00,45.47 Z
//            M 156.00,54.38
//            C 151.02,55.64 147.65,57.76 146.20,63.00
//              144.02,70.85 150.78,78.90 159.00,77.62
//              172.78,75.47 172.42,54.06 156.00,54.38 Z
//            M 39.00,57.40
//            C 24.70,62.00 28.89,78.66 40.00,80.14
//              52.97,81.88 57.82,64.21 46.96,58.45
//              44.10,56.94 42.06,57.05 39.00,57.40 Z
//            M 107.04,69.74
//            C 110.48,68.90 113.83,68.48 116.57,71.31
//              120.66,75.53 119.13,83.87 112.00,84.85
//              102.38,86.18 98.12,74.94 107.04,69.74 Z
//            M 83.11,71.02
//            C 86.28,69.91 89.86,69.35 92.77,71.60
//              97.75,75.44 96.64,84.79 89.00,85.85
//              79.74,87.13 75.55,77.14 83.11,71.02 Z
//            M 120.02,88.75
//            C 132.52,86.36 133.82,101.36 125.96,104.40
//              116.68,107.99 108.26,95.15 120.02,88.75 Z
//            M 75.02,89.61
//            C 87.68,88.53 87.49,103.68 79.00,105.68
//              69.17,108.00 64.31,93.84 75.02,89.61 Z
//            M 109.04,108.74
//            C 121.35,106.03 124.70,120.57 114.98,124.26
//              105.73,127.77 98.97,114.59 109.04,108.74 Z
//            M 152.00,108.53
//            C 160.76,107.10 169.15,107.56 175.67,114.30
//              188.41,127.45 181.94,150.19 164.00,154.32
//              139.75,159.91 124.52,129.30 143.02,113.21
//              146.11,110.52 148.17,109.67 152.00,108.53 Z
//            M 37.00,109.52
//            C 42.65,108.62 47.67,108.79 53.00,111.11
//              65.81,116.68 70.25,134.75 62.35,146.00
//              57.06,153.54 49.98,156.35 41.00,155.96
//              15.43,154.84 9.74,117.03 37.00,109.52 Z
//            M 86.02,109.74
//            C 98.54,107.01 101.14,121.75 91.98,125.26
//              82.63,128.83 75.78,115.51 86.02,109.74 Z
//            M 158.00,119.42
//            C 153.44,120.39 149.53,122.36 147.74,127.00
//              144.91,134.35 151.08,143.34 159.00,142.77
//              174.10,141.69 173.50,119.81 158.00,119.42 Z
//            M 39.00,121.51
//            C 24.17,127.92 32.27,147.27 46.00,143.57
//              53.46,141.56 57.83,131.18 50.78,124.23
//              47.25,120.74 43.57,120.71 39.00,121.51 Z
//            M 95.00,143.53
//            C 100.89,142.57 107.64,142.62 113.00,145.56
//              133.15,156.60 127.40,191.07 100.00,189.96
//              73.14,188.88 69.63,151.11 95.00,143.53 Z
//            M 98.00,155.43
//            C 82.67,161.52 90.81,181.83 105.00,177.35
//              113.68,174.61 115.54,163.25 108.79,157.43
//              105.56,154.64 101.93,154.84 98.00,155.43 Z"
//     />
//   </React.Fragment>,
//   "LondonPoolIcon"
// );
