import React from "react";
import { Typography, IconButton } from "@material-ui/core";
import Panel from "./Panel";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Link } from "react-router-dom";

export default ({ className, classes, style }) => {
  return (
    <Panel heading="FAQ" style={style} className={className} classes={classes} childrenContainerStyle={{ padding: 32 }}>
      <div
        style={{
          display: "flex",
          //flexDirection: "row-reverse",
          alignItems: "center",
          marginLeft: -16, marginTop: -8, marginBottom: 16,
        }}
      >
        <IconButton component={Link} to="/" style={{  }}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="body" style={{ cursor: "pointer" }}>
          Back
        </Typography>
      </div>
      <Typography variant="h2" className={classes.question} gutterBottom>
        Is it safe to delegate my Ada to a stake pool?
      </Typography>
      <Typography variant="body1" gutterBottom>
        Yes. Due to the power of Cardano, when you delegate your Ada using
        Daedalus, it never actually leaves your wallet, so it is not possible
        for you to lose your Ada to a stake pool.
      </Typography>
      <Typography variant="body1" gutterBottom>
        Of course you should never send your Ada to any stake pools. Instead you
        delegate your Ada through a trusted wallet like Daedalus.
      </Typography>
      <br />
      <Typography variant="h2" className={classes.question} gutterBottom>
        Can I still spend my Ada once it has been delegated to a pool?
      </Typography>
      <Typography variant="body1" gutterBottom>
        Yes, the Ada is still in your wallet and you can spend it as normal
      </Typography>
      <br />
      <Typography variant="h2" className={classes.question} gutterBottom>
        What is an epoch?
      </Typography>
      <Typography variant="body1" gutterBottom>
        The Cardano network works in cycles of 5 days, each 5 day cycle is
        called an epoch.
      </Typography>
      <br />
      <Typography variant="h2" className={classes.question} gutterBottom>
        What is the cost per epoch/fixed fee?
      </Typography>
      <Typography variant="body1" gutterBottom>
        This is not an upfront cost that you pay, it is a shared cost that comes
        out of the rewards of everyone in the pool.
      </Typography>
      <Typography variant="body1" gutterBottom>
        At the end of an epoch, when the rewards for a whole pool are
        calculated, the cost per epoch is taken out of the pool's total combined
        rewards, and given to the pool operator.
      </Typography>
      <Typography variant="body1" gutterBottom>
        This means that the larger the total Ada delegated to a pool (the
        controlled stake), the less that will be taken from your share of the
        rewards. Whereas if you were the only person in a pool, it would all
        come out of your rewards.
      </Typography>
      <Typography variant="body1" gutterBottom>
        The Cardano network has set a minimum value for cost per epoch of 340
        Ada, and this is the value most pools have chosen.
      </Typography>

      <br />
      <Typography variant="h2" className={classes.question} gutterBottom>
        What is the margin?
      </Typography>
      <Typography variant="body1" gutterBottom>
        Once the cost per epoch has been taken from the pool's combined rewards,
        a percentage of the pool's total rewards are then taken and given to the
        pool operator. This is the margin. There is a large range in the margin
        values chosen across the different pools.
      </Typography>

      <br />
      <Typography variant="h2" className={classes.question} gutterBottom>
        What is the pledge?
      </Typography>
      <Typography variant="body1" gutterBottom>
        This acts as a boost factor for the rewards a pool receives/gives out.
        e.g. if two pools have the exact same amount of total Ada delegated to
        them, with the exact same cost per epoch and margin, then the pool with
        the higher pledge will return slightly higher rewards
      </Typography>
      <Typography variant="body1" gutterBottom>
        Exactly how much more is complicated, and will in fact change over time
      </Typography>
      <Typography variant="body1" gutterBottom>
        At the moment it makes surprisingly little difference how large a pledge
        is, but the Cardano network has declared that it will increase its
        impact over the longer term
      </Typography>
      <br />
      <Typography variant="h2" className={classes.question} gutterBottom>
        What is the controlled stake?
      </Typography>
      <Typography variant="body1" gutterBottom>
        This is the total Ada delegated to the pool. This is often expressed as
        a percentage of the total delegated stake across the whole Cardano
        network. This can be important as it effects how much of an impact the
        cost per epoch will have on your rewards.
      </Typography>
    </Panel>
  );
};
