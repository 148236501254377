import React, { useState } from "react";
import { Button, IconButton, Typography } from "@material-ui/core";
import Panel from "./Panel";
import NewsletterForm from "./NewsletterForm";
import PagingItems from "./PagingItems";
import newsItemsData from "./newsItemsData";
import ArrowLeft from "@material-ui/icons/ArrowLeft";
import ArrowRight from "@material-ui/icons/ArrowRight";

const PagingControls = ({ page, perPage, total, onNext, onPrevious }) => {
  return (
    <div
      style={{
        display: "flex",
        color: "white",
        marginRight: 16,
        alignItems: "center",
        size: "small",
      }}
    >
      {/* <IconButton onClick={onPrevious}>
        <ArrowLeft/>
      </IconButton> */}
      <Button
        //variant="outlined"
        style={{ marginRight: 8 }}
        onClick={onPrevious}
      >
        <ArrowLeft />
      </Button>
      <span>{page * perPage + 1}</span>
      <span>&nbsp;to&nbsp;</span>
      <span>{(page + 1) * perPage}</span>
      <span>&nbsp;of&nbsp;</span>
      <span>{total}</span>
      <Button
        //variant="outlined"
        style={{ marginLeft: 8 }}
        onClick={onNext}
      >
        <ArrowRight />
      </Button>
    </div>
  );
};

const itemsPerPage = 10;

export default ({ classes, className, style }) => {
  const [page, setPage] = useState(0);
  const allNewsItems = newsItemsData({ classes });
  const maxPages = Math.ceil(allNewsItems.length / itemsPerPage) - 1;
  return (
    <Panel
      heading="NEWS"
      actions={[
        // <PagingControls
        //   page={page}
        //   perPage={itemsPerPage}
        //   total={allNewsItems.length}
        //   onNext={() => setPage((p) => Math.min(p + 1, maxPages))}
        //   onPrevious={() => setPage((p) => Math.max(p - 1, 0))}
        // />,
        // <div style={{ flex: 1 }} />,
        <NewsletterForm />,
      ]}
      style={style}
      childrenContainerStyle={{ padding: null, background: null }}
      className={className}
      classes={classes}
    >
      <PagingItems itemsPerPage={itemsPerPage} currentPage={page} items={allNewsItems} />
    </Panel>
  );
};
