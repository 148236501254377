import React from "react";
import { Typography } from "@material-ui/core";
import NewsItem from "./NewsItem";
import { parseISO } from "date-fns";

export default ({ classes }) => {
  return [
    <NewsItem first time={parseISO("2024-12-13T16:20:00")} classes={classes}>
      <Typography gutterBottom>Epoch 526 completed</Typography>
      <Typography>London Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Rewards out on Monday were from the previous epoch. Our luck was{" "}
            <b>111%</b>. This epoch represents an annualised return of{" "}
            <b>2.9%</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            We finished this epoch with luck of <b>104%</b>. Exactly as
            predicted. This epoch’s rewards will be on the <b>15th December</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>82%</b>
          </Typography>
        </li>
      </ul>
      <Typography>UK1 Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>0%</b>.
          </Typography>
        </li>
      </ul>
      <Typography gutterBottom>
        As always, if you have any questions please feel free to email us at{" "}
        <a href="mailto:support@londonpool.co.uk" className={classes.link}>
          support@londonpool.co.uk
        </a>{" "}
        and we'll do our best to help.
      </Typography>
    </NewsItem>,
    <NewsItem first time={parseISO("2024-12-07T20:44:00")} classes={classes}>
      <Typography gutterBottom>Epoch 525 completed</Typography>
      <Typography>London Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Rewards out on Monday were from the previous epoch. Our luck was{" "}
            <b>74%</b>. This epoch represents an annualised return of{" "}
            <b>1.9%</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            We finished this epoch with luck of <b>111%</b>. Exactly as
            predicted. This epoch’s rewards will be on the <b>10th December</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>104%</b>
          </Typography>
        </li>
      </ul>
      <Typography>UK1 Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Rewards out on Monday were from the previous epoch. Our luck was{" "}
            <b>244%</b>. This epoch represents an annualised return of{" "}
            <b>0.8%</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>0%</b>.
          </Typography>
        </li>
      </ul>
      <Typography gutterBottom>
        As always, if you have any questions please feel free to email us at{" "}
        <a href="mailto:support@londonpool.co.uk" className={classes.link}>
          support@londonpool.co.uk
        </a>{" "}
        and we'll do our best to help.
      </Typography>
    </NewsItem>,
    <NewsItem first time={parseISO("2024-12-01T20:14:00")} classes={classes}>
      <Typography gutterBottom>Epoch 524 completed</Typography>
      <Typography>London Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Rewards out on Monday were from the previous epoch. Our luck was{" "}
            <b>97%</b>. This epoch represents an annualised return of{" "}
            <b>2.5%</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            We finished this epoch with luck of <b>74%</b>. Exactly as
            predicted. This epoch’s rewards will be on the <b>5th December</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>111%</b>
          </Typography>
        </li>
      </ul>
      <Typography>UK1 Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            We finished this epoch with luck of <b>244%</b>. Exactly as
            predicted. This epoch’s rewards will be on the <b>5th December</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>0%</b>.
          </Typography>
        </li>
      </ul>
      <Typography gutterBottom>
        As always, if you have any questions please feel free to email us at{" "}
        <a href="mailto:support@londonpool.co.uk" className={classes.link}>
          support@londonpool.co.uk
        </a>{" "}
        and we'll do our best to help.
      </Typography>
    </NewsItem>,
    <NewsItem first time={parseISO("2024-11-27T20:20:00")} classes={classes}>
      <Typography gutterBottom>Epoch 523 completed</Typography>
      <Typography>London Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Rewards out on Monday were from the previous epoch. Our luck was{" "}
            <b>103%</b>. This epoch represents an annualised return of{" "}
            <b>2.7%</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            We finished this epoch with luck of <b>97%</b>. Slightly less than
            predicted as we lost 1 height battle, this was unavoidable. This
            epoch’s rewards will be on the <b>30th November</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>74%</b>
          </Typography>
        </li>
      </ul>
      <Typography>UK1 Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Rewards out on Monday were from the previous epoch. Our luck was{" "}
            <b>500%</b>. This epoch represents an annualised return of <b>8%</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>244%</b>.
          </Typography>
        </li>
      </ul>
      <Typography gutterBottom>
        As always, if you have any questions please feel free to email us at{" "}
        <a href="mailto:support@londonpool.co.uk" className={classes.link}>
          support@londonpool.co.uk
        </a>{" "}
        and we'll do our best to help.
      </Typography>
    </NewsItem>,
    <NewsItem first time={parseISO("2024-11-21T20:20:00")} classes={classes}>
      <Typography gutterBottom>Epoch 522 completed</Typography>
      <Typography>London Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Rewards out yesterday were from the previous epoch. Our luck was{" "}
            <b>96%</b>. This epoch represents an annualised return of{" "}
            <b>2.5%</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            We finished this epoch with luck of <b>103%</b>. Exactly as
            predicted. This epoch’s rewards will be on the <b>25th November</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>104%</b>
          </Typography>
        </li>
      </ul>
      <Typography>UK1 Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            We finished this epoch with luck of <b>500%</b>. Exactly as
            predicted. This epoch’s rewards will be on the <b>25th November</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>0%</b>.
          </Typography>
        </li>
      </ul>
      <Typography gutterBottom>
        As always, if you have any questions please feel free to email us at{" "}
        <a href="mailto:support@londonpool.co.uk" className={classes.link}>
          support@londonpool.co.uk
        </a>{" "}
        and we'll do our best to help.
      </Typography>
    </NewsItem>,
    <NewsItem first time={parseISO("2024-11-16T19:40:00")} classes={classes}>
      <Typography gutterBottom>Epoch 521 completed</Typography>
      <Typography>London Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Rewards out on Tuesday were from the previous epoch. Our luck was{" "}
            <b>118%</b>. This epoch represents an annualised return of{" "}
            <b>3.1%</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            We finished this epoch with luck of <b>96%</b>. Exactly as
            predicted. This epoch’s rewards will be on the <b>20th November</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>103%</b>
          </Typography>
        </li>
      </ul>
      <Typography>UK1 Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>500%</b>.
          </Typography>
        </li>
      </ul>
      <Typography gutterBottom>
        As always, if you have any questions please feel free to email us at{" "}
        <a href="mailto:support@londonpool.co.uk" className={classes.link}>
          support@londonpool.co.uk
        </a>{" "}
        and we'll do our best to help.
      </Typography>
    </NewsItem>,
    <NewsItem first time={parseISO("2024-11-12T21:21:00")} classes={classes}>
      <Typography gutterBottom>Epoch 520 completed</Typography>
      <Typography>London Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Rewards out on Tuesday were from the previous epoch. Our luck was{" "}
            <b>66%</b>. This epoch represents an annualised return of{" "}
            <b>1.7%</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            We finished this epoch with luck of <b>118%</b>. Exactly as
            predicted. This epoch’s rewards will be on the <b>15th November</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>96%</b>
          </Typography>
        </li>
      </ul>
      <Typography>UK1 Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>0%</b>.
          </Typography>
        </li>
      </ul>
      <Typography gutterBottom>
        As always, if you have any questions please feel free to email us at{" "}
        <a href="mailto:support@londonpool.co.uk" className={classes.link}>
          support@londonpool.co.uk
        </a>{" "}
        and we'll do our best to help.
      </Typography>
    </NewsItem>,
    <NewsItem first time={parseISO("2024-11-08T21:28:00")} classes={classes}>
      <Typography gutterBottom>Epoch 519 completed</Typography>
      <Typography>London Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Rewards out on Tuesday were from the previous epoch. Our luck was{" "}
            <b>88%</b>. This epoch represents an annualised return of{" "}
            <b>2.3%</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            We finished this epoch with luck of <b>66%</b>. Exactly as
            predicted. This epoch’s rewards will be on the <b>10th November</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>118%</b>
          </Typography>
        </li>
      </ul>
      <Typography>UK1 Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>0%</b>.
          </Typography>
        </li>
      </ul>
      <Typography gutterBottom>
        As always, if you have any questions please feel free to email us at{" "}
        <a href="mailto:support@londonpool.co.uk" className={classes.link}>
          support@londonpool.co.uk
        </a>{" "}
        and we'll do our best to help.
      </Typography>
    </NewsItem>,
    <NewsItem first time={parseISO("2024-11-02T22:48:00")} classes={classes}>
      <Typography gutterBottom>Epoch 518 completed</Typography>
      <Typography>London Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Rewards out on Wednesday were from the previous epoch. Our luck was{" "}
            <b>140%</b>. This epoch represents an annualised return of{" "}
            <b>3.8%</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            We finished this epoch with luck of <b>88%</b>. Less than predicted
            as we lost 2 slot battles, this was unavoidable. This epoch’s
            rewards will be on the <b>5th November</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>66%</b>
          </Typography>
        </li>
      </ul>
      <Typography>UK1 Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>0%</b>.
          </Typography>
        </li>
      </ul>
      <Typography gutterBottom>
        As always, if you have any questions please feel free to email us at{" "}
        <a href="mailto:support@londonpool.co.uk" className={classes.link}>
          support@londonpool.co.uk
        </a>{" "}
        and we'll do our best to help.
      </Typography>
    </NewsItem>,
    <NewsItem first time={parseISO("2024-10-27T13:20:00")} classes={classes}>
      <Typography gutterBottom>Epoch 517 completed</Typography>
      <Typography>London Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Rewards out on Wednesday were from the previous epoch. Our luck was{" "}
            <b>103%</b>. This epoch represents an annualised return of{" "}
            <b>2.7%</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            We finished this epoch with luck of <b>140%</b>. Exactly as
            predicted. This epoch’s rewards will be on the <b>31st October</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>103%</b>
          </Typography>
        </li>
      </ul>
      <Typography>UK1 Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>0%</b>.
          </Typography>
        </li>
      </ul>
      <Typography gutterBottom>
        As always, if you have any questions please feel free to email us at{" "}
        <a href="mailto:support@londonpool.co.uk" className={classes.link}>
          support@londonpool.co.uk
        </a>{" "}
        and we'll do our best to help.
      </Typography>
    </NewsItem>,
    <NewsItem first time={parseISO("2024-10-22T22:46:00")} classes={classes}>
      <Typography gutterBottom>Epoch 516 completed</Typography>
      <Typography>London Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Rewards out on Wednesday were from the previous epoch. Our luck was{" "}
            <b>140%</b>. This epoch represents an annualised return of{" "}
            <b>3.8%</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            We finished this epoch with luck of <b>103%</b>. Exactly as
            predicted. This epoch’s rewards will be on the <b>26th October</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>140%</b>
          </Typography>
        </li>
      </ul>
      <Typography>UK1 Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>0%</b>.
          </Typography>
        </li>
      </ul>
      <Typography gutterBottom>
        As always, if you have any questions please feel free to email us at{" "}
        <a href="mailto:support@londonpool.co.uk" className={classes.link}>
          support@londonpool.co.uk
        </a>{" "}
        and we'll do our best to help.
      </Typography>
    </NewsItem>,
    <NewsItem first time={parseISO("2024-10-19T17:17:00")} classes={classes}>
      <Typography gutterBottom>Epoch 515 completed</Typography>
      <Typography>London Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Rewards out on Wednesday were from the previous epoch. Our luck was{" "}
            <b>74%</b>. This epoch represents an annualised return of{" "}
            <b>1.9%</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            We finished this epoch with luck of <b>140%</b>. Slightly less than
            predicted as we lost 2 slot battles, this was unavoidable. This
            epoch’s rewards will be on the <b>21st October</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>103%</b>
          </Typography>
        </li>
      </ul>
      <Typography>UK1 Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>0%</b>.
          </Typography>
        </li>
      </ul>
      <Typography gutterBottom>
        As always, if you have any questions please feel free to email us at{" "}
        <a href="mailto:support@londonpool.co.uk" className={classes.link}>
          support@londonpool.co.uk
        </a>{" "}
        and we'll do our best to help.
      </Typography>
    </NewsItem>,

    <NewsItem first time={parseISO("2024-10-14T10:53:00")} classes={classes}>
      <Typography gutterBottom>Epoch 514 completed</Typography>
      <Typography>London Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Rewards out on Friday were from the previous epoch. Our luck was{" "}
            <b>112%</b>. This epoch represents an annualised return of <b>3%</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            We finished this epoch with luck of <b>74%</b>. Exactly as
            predicted. This epoch’s rewards will be on the <b>16th October</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>154%</b>
          </Typography>
        </li>
      </ul>
      <Typography>UK1 Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Rewards out on Sunday were from the previous epoch. Our luck was{" "}
            <b>250%</b>. This epoch represents an annualised return of{" "}
            <b>1.1%</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>0%</b>.
          </Typography>
        </li>
      </ul>
      <Typography gutterBottom>
        As always, if you have any questions please feel free to email us at{" "}
        <a href="mailto:support@londonpool.co.uk" className={classes.link}>
          support@londonpool.co.uk
        </a>{" "}
        and we'll do our best to help.
      </Typography>
    </NewsItem>,

    <NewsItem first time={parseISO("2024-10-08T19:22:00")} classes={classes}>
      <Typography gutterBottom>Epoch 513 completed</Typography>
      <Typography>London Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Rewards out on Sunday were from the previous epoch. Our luck was{" "}
            <b>104%</b>. This epoch represents an annualised return of{" "}
            <b>2.8%</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            We finished this epoch with luck of <b>112%</b>. Exactly as
            predicted. This epoch’s rewards will be on the <b>11th October</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>74%</b>
          </Typography>
        </li>
      </ul>
      <Typography>UK1 Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            We finished this epoch with luck of <b>250%</b>. Exactly as
            predicted. This epoch’s rewards will be on the <b>11th October</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>0%</b>.
          </Typography>
        </li>
      </ul>
      <Typography gutterBottom>
        As always, if you have any questions please feel free to email us at{" "}
        <a href="mailto:support@londonpool.co.uk" className={classes.link}>
          support@londonpool.co.uk
        </a>{" "}
        and we'll do our best to help.
      </Typography>
    </NewsItem>,
    <NewsItem first time={parseISO("2024-10-02T20:42:00")} classes={classes}>
      <Typography gutterBottom>Epoch 512 completed</Typography>
      <Typography>London Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Rewards out on Friday were from the previous epoch. Our luck was{" "}
            <b>44%</b>. This epoch represents an annualised return of{" "}
            <b>1.1%</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            We finished this epoch with luck of <b>104%</b>. Exactly as
            predicted. This epoch’s rewards will be on the <b>6th October</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>119%</b>
          </Typography>
        </li>
      </ul>
      <Typography>UK1 Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Rewards out on Friday were from the previous epoch. Our luck was{" "}
            <b>250%</b>. This epoch represents an annualised return of{" "}
            <b>1.1%</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>250%</b>.
          </Typography>
        </li>
      </ul>
      <Typography gutterBottom>
        As always, if you have any questions please feel free to email us at{" "}
        <a href="mailto:support@londonpool.co.uk" className={classes.link}>
          support@londonpool.co.uk
        </a>{" "}
        and we'll do our best to help.
      </Typography>
    </NewsItem>,
    <NewsItem first time={parseISO("2024-09-26T21:47:00")} classes={classes}>
      <Typography gutterBottom>Epoch 511 completed</Typography>
      <Typography>London Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Rewards out on Friday were from the previous epoch. Our luck was{" "}
            <b>103%</b>. This epoch represents an annualised return of{" "}
            <b>2.8%</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            We finished this epoch with luck of <b>44%</b>. Exactly as
            predicted. This epoch’s rewards will be on the <b>1st October</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>111%</b>
          </Typography>
        </li>
      </ul>
      <Typography>UK1 Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            We finished this epoch with luck of <b>250%</b>. Exactly as
            predicted. This epoch’s rewards will be on the <b>1st October</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>0%</b>.
          </Typography>
        </li>
      </ul>
      <Typography gutterBottom>
        As always, if you have any questions please feel free to email us at{" "}
        <a href="mailto:support@londonpool.co.uk" className={classes.link}>
          support@londonpool.co.uk
        </a>{" "}
        and we'll do our best to help.
      </Typography>
    </NewsItem>,
    <NewsItem first time={parseISO("2024-09-22T21:08:00")} classes={classes}>
      <Typography gutterBottom>Epoch 510 completed</Typography>
      <Typography>London Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Rewards out on Friday were from the previous epoch. Our luck was{" "}
            <b>89%</b>. This epoch represents an annualised return of{" "}
            <b>2.4%</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            We finished this epoch with luck of <b>103%</b>. Exactly as
            predicted. This epoch’s rewards will be on the <b>26th September</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>44%</b>
          </Typography>
        </li>
      </ul>
      <Typography>UK1 Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Rewards out on Friday were from the previous epoch. Our luck was{" "}
            <b>500%</b>. This epoch represents an annualised return of{" "}
            <b>8.6%</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>250%</b>.
          </Typography>
        </li>
      </ul>
      <Typography gutterBottom>
        As always, if you have any questions please feel free to email us at{" "}
        <a href="mailto:support@londonpool.co.uk" className={classes.link}>
          support@londonpool.co.uk
        </a>{" "}
        and we'll do our best to help.
      </Typography>
    </NewsItem>,
    <NewsItem first time={parseISO("2024-09-18T20:47:00")} classes={classes}>
      <Typography gutterBottom>Epoch 509 completed</Typography>
      <Typography>London Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Rewards out on Friday were from the previous epoch. Our luck was{" "}
            <b>118%</b>. This epoch represents an annualised return of{" "}
            <b>3.2%</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            We finished this epoch with luck of <b>89%</b>. Exactly as
            predicted. This epoch’s rewards will be on the <b>21st September</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>103%</b>
          </Typography>
        </li>
      </ul>
      <Typography>UK1 Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            We finished this epoch with luck of <b>500%</b>. Exactly as
            predicted. This epoch’s rewards will be on the <b>21st September</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>0%</b>.
          </Typography>
        </li>
      </ul>
      <Typography gutterBottom>
        As always, if you have any questions please feel free to email us at{" "}
        <a href="mailto:support@londonpool.co.uk" className={classes.link}>
          support@londonpool.co.uk
        </a>{" "}
        and we'll do our best to help.
      </Typography>
    </NewsItem>,
    <NewsItem first time={parseISO("2024-09-13T08:42:00")} classes={classes}>
      <Typography gutterBottom>Epoch 508 completed</Typography>
      <Typography>London Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Rewards out on Friday were from the previous epoch. Our luck was{" "}
            <b>73%</b>. This epoch represents an annualised return of{" "}
            <b>1.9%</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            We finished this epoch with luck of <b>118%</b>. Slightly less than
            predicted as we lost 1 slot battle, this was unavoidable. This
            epoch’s rewards will be on the <b>16th September</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>89%</b>
          </Typography>
        </li>
      </ul>
      <Typography>UK1 Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>500%</b>.
          </Typography>
        </li>
      </ul>
      <Typography gutterBottom>
        As always, if you have any questions please feel free to email us at{" "}
        <a href="mailto:support@londonpool.co.uk" className={classes.link}>
          support@londonpool.co.uk
        </a>{" "}
        and we'll do our best to help.
      </Typography>
    </NewsItem>,
    <NewsItem first time={parseISO("2024-09-08T18:20:00")} classes={classes}>
      <Typography gutterBottom>Epoch 507 completed</Typography>
      <Typography>London Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Rewards out on Friday were from the previous epoch. Our luck was{" "}
            <b>138%</b>. This epoch represents an annualised return of{" "}
            <b>3.8%</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            We finished this epoch with luck of <b>73%</b>. Exactly as
            predicted. This epoch’s rewards will be on the <b>11th September</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>125%</b>
          </Typography>
        </li>
      </ul>
      <Typography>UK1 Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Rewards out on Tuesday were from the previous epoch. Our luck was{" "}
            <b>250%</b>. This epoch represents an annualised return of{" "}
            <b>1.2%</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>0%</b>.
          </Typography>
        </li>
      </ul>
      <Typography gutterBottom>
        As always, if you have any questions please feel free to email us at{" "}
        <a href="mailto:support@londonpool.co.uk" className={classes.link}>
          support@londonpool.co.uk
        </a>{" "}
        and we'll do our best to help.
      </Typography>
    </NewsItem>,
  ];
};
