import { useState, useLayoutEffect } from "react";
import { useDebounce } from "use-debounce/lib";

export default () => {
  const [screenWidth, setScreenWidth] = useState(null);
  const [screenWidthDebounced] = useDebounce(screenWidth, 100);

  useLayoutEffect(() => {
    function updateScreenSize() {
      setScreenWidth(window.innerWidth);
    }
    window.addEventListener("resize", updateScreenSize);
    updateScreenSize();
    return () => window.removeEventListener("resize", updateScreenSize);
  }, []);

  return screenWidthDebounced || window.innerWidth;
};
