import React from "react";

export default ({ children, last }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        background: "rgba(40,39,48,0.89)",
        padding: 16,
        marginBottom: last ? null : 1,
        borderRadius: last ? "0px 0px 5px 5px" : null,
      }}
    >
      {children}
    </div>
  );
};
