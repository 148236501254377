import React from "react";
import { Typography, Tooltip } from "@material-ui/core";
import PanelItem from "./PanelItem";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import { formatDistanceToNow, format } from "date-fns";

export default ({ children, last, time, classes }) => {
  return (
    <PanelItem last={last}>
      <div style={{ display: "flex" }}>
        <InfoIcon
          style={{ marginRight: 16, marginTop: 4, width: 32, height: 32, color: "#ddd" }}
        />
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Tooltip
            classes={{ tooltip: classes.tooltip }}
            style={{}}
            title={format(time, "yyyy-MM-dd HH:mm")}
          >
            <Typography
              color="textSecondary"
              style={{ marginLeft: 0, alignSelf: "flex-start" }}
            >
              {time ? formatDistanceToNow(time) : ""} ago
            </Typography>
          </Tooltip>
          <div style={{ display: "flex", flexDirection: "column" }}>
            {children}
          </div>
        </div>
      </div>
    </PanelItem>
  );
};
