import React, { useState } from "react";
import { Button, InputAdornment, TextField } from "@material-ui/core";
import EmailIcon from "@material-ui/icons/Email";

export default ({ classes, className }) => {
  const [emailAddress, setEmailAddress] = useState("");
  return (
    <form
      action="https://londonpool.us1.list-manage.com/subscribe/post?u=3bbc4807d0e4cc0d5844223ee&amp;id=996cac72cc"
      method="POST"
      id="mc-embedded-subscribe-form"
      target="_blank"
      noValidate
    >
      <input type="hidden" name="u" value="3bbc4807d0e4cc0d5844223ee" />
      <input type="hidden" name="id" value="996cac72cc" />
      <TextField
        type="email"
        name="EMAIL"
        placeholder="Your email address"
        value={emailAddress}
        //style={{ width: 200 }}
        onChange={(e) => setEmailAddress(e.target.value)}
        
        InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <EmailIcon />
              </InputAdornment>
            ),
          }}
      />
      <Button
        type="submit"
        name="subscribe"
        id="mc-embedded-subscribe"
        variant="outlined"
        style={{ marginLeft: 16 }}
      >
        Subscribe
      </Button>
    </form>
  );
};

// <!-- Begin Mailchimp Signup Form -->
// <link href="//cdn-images.mailchimp.com/embedcode/classic-10_7.css" rel="stylesheet" type="text/css">
// <style type="text/css">
// 	#mc_embed_signup{background:#fff; clear:left; font:14px Helvetica,Arial,sans-serif; }
// 	/* Add your own Mailchimp form style overrides in your site stylesheet or in this style block.
// 	   We recommend moving this block and the preceding CSS link to the HEAD of your HTML file. */
// </style>
// <div id="mc_embed_signup">
// <form action="https://londonpool.us1.list-manage.com/subscribe/post?u=3bbc4807d0e4cc0d5844223ee&amp;id=996cac72cc" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
//     <div id="mc_embed_signup_scroll">
// 	<h2>Subscribe</h2>
// <div class="indicates-required"><span class="asterisk">*</span> indicates required</div>
// <div class="mc-field-group">
// 	<label for="mce-EMAIL">Email Address  <span class="asterisk">*</span>
// </label>
// 	<input type="email" value="" name="EMAIL" class="required email" id="mce-EMAIL">
// </div>
// 	<div id="mce-responses" class="clear">
// 		<div class="response" id="mce-error-response" style="display:none"></div>
// 		<div class="response" id="mce-success-response" style="display:none"></div>
// 	</div>    <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
//     <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_3bbc4807d0e4cc0d5844223ee_996cac72cc" tabindex="-1" value=""></div>
//     <div class="clear"><input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" class="button"></div>
//     </div>
// </form>
// </div>
// <script type='text/javascript' src='//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js'></script><script type='text/javascript'>(function($) {window.fnames = new Array(); window.ftypes = new Array();fnames[0]='EMAIL';ftypes[0]='email';fnames[1]='FNAME';ftypes[1]='text';fnames[2]='LNAME';ftypes[2]='text';fnames[3]='ADDRESS';ftypes[3]='address';fnames[4]='PHONE';ftypes[4]='phone';fnames[5]='BIRTHDAY';ftypes[5]='birthday';}(jQuery));var $mcj = jQuery.noConflict(true);</script>
// <!--End mc_embed_signup--></div>
