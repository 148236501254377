import React from "react";
import { Typography } from "@material-ui/core";
import Panel from "./Panel";
import { Link } from "react-router-dom";
import TwitterIcon from "@material-ui/icons/Twitter";
import EmailIcon from "@material-ui/icons/Email";

export default ({ classes, className }) => {
  return (
    <Panel
      heading="WELCOME"
      actions={[
        <a
          href="mailto:support@londonpool.co.uk"
          target="_blank"
          rel="noopener noreferrer"
          className={classes.contactLink}
          style={{ paddingRight: 4 }}
        >
          <EmailIcon />
        </a>,
      ]}
      className={className}
      classes={classes}
    >
      <Typography gutterBottom>
        Run by London based IT professionals with decades of experience
        developing and supporting critical systems.
      </Typography>
      <Typography gutterBottom>
        We have been up and running since the beginning in 2020, and we have
        produced rewards in every epoch so far.
      </Typography>
      <Typography gutterBottom>
        Sign up to our newsletter for regular updates every epoch (5 days), or
        feel free to{" "}
        <a href="mailto:support@londonpool.co.uk" className={classes.link}>
          email us
        </a>{" "}
        if you have any questions.
      </Typography>
      <Typography>
        Please see our{" "}
        <Link to="/faq" className={classes.link}>
          FAQ
        </Link>{" "}
        for any questions on delegation or terminology.
      </Typography>
    </Panel>
  );
};
