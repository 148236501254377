import React from "react";
import { Typography } from "@material-ui/core";

export default ({
  heading,
  actions,
  children,
  style,
  childrenContainerStyle,
  className,
  classes,
}) => {
  return (
    <div
      className={className}
      style={{
        display: "flex",
        flexDirection: "column",
        margin: "8px 8px",
        ...style,
      }}
    >
      <div
        className={classes.header}
        style={{
          padding: 16,
          display: "flex",
          textAlign: "left",
          marginBottom: 1,
          borderRadius: "5px 5px 0px 0px",
          alignItems: 'center'
        }}
      >
        <Typography
          variant="h6"
          style={{
            flex: 1,
            color: "#FFF"
          }}
        >
          {heading}
        </Typography>
        {actions}
      </div>
      <div
        style={{
          padding: 16,
          background: "rgba(40,39,48,0.89)",
          borderRadius: "0px 0px 5px 5px",
          ...childrenContainerStyle,
        }}
      >
        {children}
      </div>
    </div>
  );
};
