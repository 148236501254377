import React from "react";
import { Typography } from "@material-ui/core";
import Panel from "./Panel";

export default ({ classes, className }) => {
  return (
    <Panel heading="FEES" className={className} classes={classes}>
      <Typography variant="body1" gutterBottom>
        We have chosen an initial margin of <b>0%</b> for the LONDN and UK1 pools to minimise overheads to you while the pools
        fill up with people
      </Typography>
      <Typography variant="body1" gutterBottom>
        Once the pools are established with enough people to comfortably share the fixed costs we
        will set the margin to <b>1%</b> or lower
      </Typography>
    </Panel>
  );
};
