import React from "react";
import "./App.css";
import {
  Typography,
  CssBaseline,
  createMuiTheme,
  ThemeProvider,
  AppBar,
  Toolbar,
  makeStyles,
} from "@material-ui/core";
import LondonPoolIcon from "./LondonPoolIcon";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import WelcomePanel from "./WelcomePanel";
import PoolPanel from "./PoolPanel";
import NewsPanel from "./NewsPanel";
import FeesPanel from "./FeesPanel";
import useWindowWidth from "./useWindowWidth";
import FaqPanel from "./FaqPanel";
import PrivacyPolicy from "./PrivacyPolicy";

const theme = createMuiTheme({
  palette: {
    type: "dark",
  },
  typography: {
    fontSize: 12,
    h1: {
      fontSize: "1.5rem",
    },
    h2: {
      fontSize: "1.4rem",
    },
  },
});

const useStyles = makeStyles((theme) => ({
  "@keyframes fadeIn1": {
    "0%": { opacity: 0 },
    "50%": { opacity: 1 },
  },
  "@keyframes fadeIn2": {
    "0%": { opacity: 0 },
    "5%": { opacity: 0 },
    "55%": { opacity: 1 },
  },
  "@keyframes fadeIn3": {
    "0%": { opacity: 0 },
    "10%": { opacity: 0 },
    "65%": { opacity: 1 },
  },
  "@keyframes fadeIn4": {
    "0%": { opacity: 0 },
    "15%": { opacity: 0 },
    "70%": { opacity: 1 },
  },
  "@keyframes fadeIn5": {
    "0%": { opacity: 0 },
    "20%": { opacity: 0 },
    "75%": { opacity: 1 },
  },
  "@keyframes fadeIn6": {
    "0%": { opacity: 0 },
    "25%": { opacity: 0 },
    "80%": { opacity: 1 },
  },
  fadeIn1: {
    animationName: "$fadeIn1",
    animationDuration: "3s",
  },
  fadeIn2: {
    animationName: "$fadeIn2",
    animationDuration: "3s",
  },
  fadeIn3: {
    animationName: "$fadeIn3",
    animationDuration: "3s",
  },
  fadeIn4: {
    animationName: "$fadeIn4",
    animationDuration: "3s",
  },
  fadeIn5: {
    animationName: "$fadeIn5",
    animationDuration: "3s",
  },
  fadeIn6: {
    animationName: "$fadeIn6",
    animationDuration: "3s",
  },
  appBar: {
    backgroundColor: "rgba(40,39,48,0.89)",
  },
  header: {
    backgroundColor: "rgba(40,39,48,0.522)",
    color: "#555",
  },
  appToolbar: {
    display: "flex",
  },
  title: {
    marginLeft: 16,
    minWidth: 100,
    textAlign: "left",
  },
  link: {
    color: "#ddd",
    "&hover": {
      color: "#ddd",
    },
    "&visited": {
      color: "#ddd",
    },
  },
  contactLink: {
    marginLeft: 8,
    marginRight: 8,
    marginTop: 2,
    color: "#ddd",
    "&hover": {
      color: "#ddd",
    },
    "&visited": {
      color: "#ddd",
    },
    textDecoration: "underline",
  },
  tooltip: {
    maxWidth: 400,
    fontSize: 14,
  },
}));

function App() {
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <AppInner />
      </ThemeProvider>
    </Router>
  );
}

function AppInner() {
  const classes = useStyles();
  const windowWidth = useWindowWidth();
  const isWindowSmall = windowWidth < 1000;
  return (
    <div className="App" style={{}}>
      <CssBaseline />
      <AppBar position="static" className={classes.appBar}>
        <Toolbar variant="regular" className={classes.appToolbar}>
          <LondonPoolIcon
            style={{
              minWidth: 32,
              minHeight: 32,
              color: "white",
            }}
          />
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="h6" color="white" className={classes.title}>
              The London Pool
            </Typography>
            {true && (
              <Typography
                variant="subtitle1"
                color="textSecondary"
                className={classes.title}
                style={{ marginTop: -6 }}
              >
                A Cardano staking pool in London
              </Typography>
            )}
          </div>
          <div style={{ flex: 1 }} />
        </Toolbar>
      </AppBar>

      <Switch>
        <Route exact path="/faq">
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <FaqPanel
              style={{ maxWidth: 800, margin: 16 }}
              className={classes.fadeIn1}
              classes={classes}
            />
          </div>
        </Route>
        <Route exact path="/privacypolicy">
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <PrivacyPolicy
              style={{ maxWidth: 800, margin: 16 }}
              className={classes.fadeIn1}
              classes={classes}
            />
          </div>
        </Route>
        <Route exact path="/">
          {isWindowSmall ? (
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                flex: 1,
              }}
            >
              <div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    margin: "8px 0px",
                    maxWidth: 600,
                    flex: 1,
                  }}
                >
                  <WelcomePanel className={classes.fadeIn1} classes={classes} />
                  <PoolPanel className={classes.fadeIn2} classes={classes} />
                  <NewsPanel className={classes.fadeIn3} classes={classes} />
                  <FeesPanel className={classes.fadeIn4} classes={classes} />
                </div>
                <div
                  style={{
                    //backgroundColor: "rgba(40,39,48,0.89)",
                    display: "flex",
                  }}
                >
                  <div style={{ flex: 1 }} />
                  <span style={{ margin: 4 }}>
                    <Link to={"/privacypolicy"} className={classes.link}>
                      Privacy Policy
                    </Link>
                  </span>
                  <div style={{ flex: 1 }} />
                </div>
              </div>
            </div>
          ) : (
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  margin: "8px 8px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    margin: "0px 0px",
                    flex: 1,
                    maxWidth: 600,
                  }}
                >
                  <WelcomePanel className={classes.fadeIn1} classes={classes} />
                  <PoolPanel className={classes.fadeIn2} classes={classes} />
                  <FeesPanel className={classes.fadeIn3} classes={classes} />
                </div>
                <NewsPanel
                  className={classes.fadeIn4}
                  style={{ flex: 2 }}
                  classes={classes}
                />
              </div>
              <div
                style={{
                  backgroundColor: "rgba(40,39,48,0.89)",
                  display: "flex",
                }}
              >
                <div style={{ flex: 1 }} />
                <span style={{ margin: 4 }}>
                  <Link to={"/privacypolicy"} className={classes.link}>
                    Privacy Policy
                  </Link>
                </span>
                <div style={{ flex: 1 }} />
              </div>
            </div>
          )}
        </Route>
      </Switch>
    </div>
  );
}

export default App;
