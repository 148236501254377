import React from "react";
import { Typography, Tooltip, IconButton } from "@material-ui/core";
import Panel from "./Panel";
import PanelItem from "./PanelItem";
import CopyIcon from "@material-ui/icons/FileCopyOutlined";
import copyToClipboard from 'copy-to-clipboard';
import EpochCountdown from "./EpochCountdown";

export default ({ classes, className }) => {
  return (
    <Panel
      heading="THE POOLS"
      childrenContainerStyle={{ padding: null, background: null }}
      className={className}
      classes={classes}
      actions={[<EpochCountdown/>]}
    >
      <PanelItem first>
        <div style={{ display: "flex" }}>
          <Typography style={{ minWidth: 85, marginBottom: 4 }}>
            Ticker
          </Typography>
          <Typography
            style={{
              flex: 1,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            <b>LONDN</b> <b style={{ color: '#33691E', marginLeft: 8 }}>SPACE AVAILABLE</b>
          </Typography>
        </div>
        {/* <div style={{ display: "flex" }}>
              <Typography style={{ minWidth: 75 }}>
                Delegation
              </Typography>
              <Typography style={{ flex: 3 }}>
                <b>12</b> Million from <b>100</b> people
              </Typography>
            </div> */}
        <div style={{ display: "flex", marginBottom: 4 }}>
          <Typography style={{ minWidth: 85 }}>Margin</Typography>
          <Typography style={{  }}>
            <b>0%</b>
          </Typography>
        </div>
        <div style={{ display: "flex", marginBottom: 4 }}>
          <Typography style={{ minWidth: 85 }}>Fixed cost</Typography>
          <Typography style={{ flex: 3 }}>
            <b>340 ₳</b>
          </Typography>
        </div>
        <div style={{ display: "flex", marginBottom: 4 }}>
          <Typography style={{ minWidth: 85 }}>Pledge</Typography>
          <Typography style={{ flex: 3 }}>
            <b>301,000 ₳</b>
          </Typography>
        </div>
        <div style={{ display: "flex", marginBottom: 4 }}>
          <Typography style={{ minWidth: 85 }}>Pool ID</Typography>
          <Typography
            style={{
              flex: 1,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: 160
            }}
          >
            3b0a33f53dfde8ffd7f05e16aeb9b888a019b383c43cbad26c517985
          </Typography>
          <Tooltip
            title="Copy the pool id to the clipboard"
            classes={{ tooltip: classes.tooltip }}
          >
            <IconButton
              style={{ marginLeft: 8, alignSelf: "center", }}
              aria-label="copy pool id"
              size="small"
              onClick={() => copyToClipboard('3b0a33f53dfde8ffd7f05e16aeb9b888a019b383c43cbad26c517985')}
              
            >
              <CopyIcon
                style={{
                  width: 16,
                  height: 16,
                  
                }}
              />
            </IconButton>
          </Tooltip>
        </div>
        {/* <Typography color='textSecondary' style={{ marginTop: 8 }}>
        Once the pool is saturated the margin will be increased, it will not be more than 1.9%
        </Typography> */}
      </PanelItem>
      <PanelItem last>
      <div style={{ display: "flex" }}>
          <Typography style={{ minWidth: 85, marginBottom: 4 }}>
            Ticker
          </Typography>
          <Typography
            style={{
              flex: 1,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            <b>UK1</b> <b style={{ color: '#33691E', marginLeft: 8 }}>SPACE AVAILABLE</b>
          </Typography>
        </div>
        {/* <div style={{ display: "flex" }}>
              <Typography style={{ minWidth: 75 }}>
                Delegation
              </Typography>
              <Typography style={{ flex: 3 }}>
                <b>12</b> Million from <b>100</b> people
              </Typography>
            </div> */}
        <div style={{ display: "flex", marginBottom: 4 }}>
          <Typography style={{ minWidth: 85 }}>Margin</Typography>
          <Typography style={{  }}>
            <b>0%</b>
          </Typography>
          {/* <Typography color='textSecondary' style={{ marginLeft: 4 }}>
            once the pool is filled it will be 1%
          </Typography> */}
        </div>
        <div style={{ display: "flex", marginBottom: 4 }}>
          <Typography style={{ minWidth: 85 }}>Fixed cost</Typography>
          <Typography style={{ flex: 3 }}>
            <b>340 ₳</b>
          </Typography>
        </div>
        <div style={{ display: "flex", marginBottom: 4 }}>
          <Typography style={{ minWidth: 85 }}>Pledge</Typography>
          <Typography style={{ flex: 3 }}>
            <b>22,000 ₳</b>
          </Typography>
        </div>
        <div style={{ display: "flex", marginBottom: 4 }}>
          <Typography style={{ minWidth: 85 }}>Pool ID</Typography>
          <Typography
            style={{
              flex: 1,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: 160
            }}
          >
            81ab938d86519a6459ce524325ed839f52b48d69465453627e55efeb
          </Typography>
          <Tooltip
            title="Copy the pool id to the clipboard"
            classes={{ tooltip: classes.tooltip }}
          >
            <IconButton
              style={{ marginLeft: 8, alignSelf: "center", }}
              aria-label="copy pool id"
              size="small"
              onClick={() => copyToClipboard('81ab938d86519a6459ce524325ed839f52b48d69465453627e55efeb')}
              
            >
              <CopyIcon
                style={{
                  width: 16,
                  height: 16,
                  
                }}
              />
            </IconButton>
          </Tooltip>
        </div>
      </PanelItem>
    </Panel>
  );
};