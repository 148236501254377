import React, { useState, useEffect, useRef } from "react";
import { Typography } from "@material-ui/core";

const firstShellyEpochStartTime = 1596059091000;
//const firstShellyEpoch = 208;
const millisecondsPerEpoch = 5 * 24 * 60 * 60 * 1000;
const day = 24 * 60 * 60 * 1000;
const hour = 60 * 60 * 1000;
const min = 60 * 1000;
const second = 1000;

export default () => {
  //const nextEpochEnd = useState();
  // eslint-disable-next-line no-unused-vars
  const [updateCounter, setUpdateCounter] = useState(0);
  const intervalRef = useRef();
  const now = Date.now();
  const diff = now - firstShellyEpochStartTime;
  const epochOffset = Math.floor(diff / millisecondsPerEpoch);
  //const epochNo = epochOffset + firstShellyEpoch;
  const nextEpochStartTime =
    (epochOffset + 1) * millisecondsPerEpoch + firstShellyEpochStartTime;
  const timeRemainingInEpoch = nextEpochStartTime - now;

  let remaining = timeRemainingInEpoch;
  const days = Math.floor(remaining / day);
  remaining -= days * day;
  const hours = Math.floor(remaining / hour);
  remaining -= hours * hour;
  const mins = Math.floor(remaining / min);
  remaining -= mins * min;
  const secs = Math.floor(remaining / second);

  useEffect(() => {
    console.log("Setting timeout");
    intervalRef.current = setInterval(() => {
      setUpdateCounter((v) => v + 1);
    }, 1000);

    return () => {
      console.log("Clearing timeout");
      clearInterval(intervalRef.current);
    };
  }, []);
  
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Typography style={{ color: "white", fontSize: 10, textAlign: "right" }}>
        Next epoch starts in
      </Typography>
      <Typography style={{ color: "white", textAlign: "right", fontSize: 14 }}>
        {days > 0 && `${days}d `}
        {(hours > 0 || days > 0) && `${hours}h `}{" "}
        {(hours > 0 || days > 0 || mins > 0) && `${mins}m `}{" "}
        {(hours > 0 || days > 0 || mins > 0 || secs > 0) && `${secs}s`}
      </Typography>
    </div>
  );
};
